<div class="d-flex justify-content-center">
  <div class="text-center font-weight-light">
    <fa-icon [icon]="faIcon" size="7x" class="{{ faIconClass }}"></fa-icon>
    <div
      *ngIf="showSubContext"
      [innerHTML]="'filesOverlayNoRowsTemplateDrive' | translate"
      class="mt-2"
    ></div>
    <div
      *ngIf="translateString"
      [innerHTML]="translateString | translate"
    ></div>
  </div>
</div>
