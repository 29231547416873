import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  selector: '[ngForReady]',
  standalone: false,
})
export class NgForReadyDirective {
  @Output() isReady: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() set ngForReady(isReady: boolean) {
    if (isReady) this.isReady.emit(isReady);
  }
}
