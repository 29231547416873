import {
  AfterContentInit,
  AfterViewInit,
  Directive,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { TextMaskService } from '../text-mask/text-mask.service';

@Directive({
  selector: '[cc-month-year]',
  providers: [],
  standalone: false,
})
export class MonthYearDirective
  implements OnInit, OnDestroy, OnChanges, AfterContentInit, AfterViewInit
{
  private inputField: HTMLInputElement;
  private mask = [/\d/, /\d/, '/', /\d/, /\d/];

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private vanillaTextMaskService: TextMaskService,
  ) {}

  ngAfterContentInit(): void {}

  ngAfterViewInit(): void {
    const element = this.elementRef.nativeElement;
    this.inputField = element.querySelector("[autocomplete='cc-exp-year']");
    this.vanillaTextMaskService.maskInput(this.inputField, {
      mask: this.mask,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {}

  ngOnInit(): void {}
}
