/**
 * Created by Ramor11 on 3/31/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { OverlayNoRowsTemplateComponent } from './overlayNoRowsTemplate.component';

@NgModule({
  declarations: [OverlayNoRowsTemplateComponent],
  imports: [CommonModule, TranslateModule, AgGridModule, FontAwesomeModule],
  exports: [OverlayNoRowsTemplateComponent],
})
export class OverlayNoRowsTemplateModule {}
