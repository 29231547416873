/**
 * Created by Ramor11 on 3/31/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForReadyDirective } from './ngForReady.directive';
import { DropdownItemDirective } from './dropdown-item.directive';
import { CreditcardTypeDirectiveModule } from './creditcard/creditcard.type.directive.module';

@NgModule({
  declarations: [NgForReadyDirective, DropdownItemDirective],
  imports: [CommonModule, CreditcardTypeDirectiveModule],
  exports: [
    NgForReadyDirective,
    DropdownItemDirective,
    CreditcardTypeDirectiveModule,
  ],
})
export class EqCommonDirectivesModule {}
