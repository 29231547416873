/**
 * Created by Ramor11 on 3/31/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditCardTypeDirective } from './creditcard.type.directive';
import { MonthYearDirective } from './month.year.directive';
import { TextMaskDirectiveModule } from '../text-mask/text-mask.directive.module';

@NgModule({
  declarations: [CreditCardTypeDirective, MonthYearDirective],
  imports: [CommonModule, TextMaskDirectiveModule],
  exports: [
    TextMaskDirectiveModule,
    CreditCardTypeDirective,
    MonthYearDirective,
  ],
  providers: [],
})
export class CreditcardTypeDirectiveModule {}
