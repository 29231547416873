import {
  AfterContentInit,
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { TextMaskService } from '../text-mask/text-mask.service';
import { Listener } from '../../services/listeners';

@Directive({
  selector: '[creditcardtype]',
  providers: [],
  standalone: false,
})
export class CreditCardTypeDirective
  implements OnInit, OnDestroy, OnChanges, AfterContentInit, AfterViewInit
{
  @Output('ccType') ccType: EventEmitter<string> = new EventEmitter<string>();

  private inputField: HTMLInputElement;
  private mask = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  private listener: Listener = new Listener();

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private vanillaTextMaskService: TextMaskService,
  ) {}

  ngAfterContentInit(): void {}

  private get InputElement(): HTMLInputElement {
    const element = this.elementRef.nativeElement;
    return element.querySelector("[autocomplete='cc-number']");
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.inputField = this.InputElement;
      this.listener
        .add(
          this.renderer.listen(this.inputField, 'keyup', (e) => {
            this.parseValue(this.inputField.value);
          }),
        )
        .add(
          this.renderer.listen(this.inputField, 'paste', (e) => {
            this.parseValue(this.inputField.value);
          }),
        );

      this.vanillaTextMaskService.maskInput(this.inputField, {
        mask: this.mask,
      });
    });
  }

  private parseValue(value) {
    // Disabled because CC field is being deselected when typing credit card.
    const ccType = /^5[1-5]/.test(value)
      ? 'mastercard'
      : /^4/.test(value)
        ? 'visa'
        : /^3[47]/.test(value)
          ? 'amex'
          : /^300/.test(value)
            ? 'dinersclub'
            : /^35/.test(value)
              ? 'jcb'
              : /^6011|65|64[4-9]|622(1(2[6-9]|[3-9]\d)|[2-8]\d{2}|9([01]\d|2[0-5]))/.test(
                    value,
                  )
                ? 'discover'
                : undefined;

    this.ccType.emit(ccType);

    return value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.InputElement) this.parseValue(this.InputElement.value);
  }

  ngOnDestroy(): void {
    this.listener.unsubscribe();
  }

  ngOnInit(): void {}
}
